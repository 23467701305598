import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("../views/IndexView.vue"),
        children: [
            {
                path: "/",
                name: "home",
                component: () => import("../views/HomePage.vue"),
                meta: {
                    title: "网站首页",
                    navIndex: 0,
                },
            },
            {
                path: "/test",
                name: "test",
                component: () => import("../views/test.vue"),
                meta: {
                    title: "品牌介绍",
                    // navIndex: 1,
                },
            },
            {
                path: "/brand",
                name: "brand",
                component: () => import("../views/brandPage.vue"),
                meta: {
                    title: "品牌介绍",
                    navIndex: 1,
                },
            },
            {
                path: "/honor",
                name: "honor",
                component: () => import("../views/HonorPage.vue"),
                meta: {
                    title: "荣誉资质",
                    navIndex: 2,
                },
            },
            {
                path: "/product/:index",
                name: "product",
                props: true,
                component: () => import("../views/ProductPage.vue"),
                meta: {
                    title: "产品中心",
                    navIndex: 3,
                },
            },
            {
                path: "/technical",
                name: "technical",
                component: () => import("../views/TechnicalPage.vue"),
                meta: {
                    title: "技术支持",
                    navIndex: 4,
                },
            },
            {
                path: "/project",
                name: "project",
                component: () => import("../views/ProjectPage.vue"),
                meta: {
                    title: "项目案例",
                    navIndex: 5,
                },
            },
            {
                path: "/news",
                name: "news",
                component: () => import("../views/NewsPage.vue"),
                meta: {
                    title: "新闻动态",
                    navIndex: 6,
                },
            },
            {
                path: "/contact",
                name: "contact",
                component: () => import("../views/ContactPage.vue"),
                meta: {
                    title: "联系我们",
                    navIndex: 7,
                },
            },
            {
                path: "/inquiry/:id",
                name: "inquiry",
                component: () => import("../views/InquiryPage.vue"),
                meta: {
                    title: "产品询价",
                    navIndex: 3,
                },
            },
            {
                path: "/product_detail/:id/:index",
                name: "product_detail",
                component: () => import("../views/ProductDetail.vue"),
                meta: {
                    title: "产品详情",
                    // navIndex: 3,
                },
            },
            {
                path: "/news_detail/:id",
                name: "news_detail",
                component: () => import("../views/NewsDetail.vue"),
                meta: {
                    title: "新闻详情",
                    // navIndex: 3,
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 如果有savedPosition，就跳转到该位置
        if (savedPosition) {
            return savedPosition;
        } else {
            // 如果没有，就返回顶部
            return { x: 0, y: 0 };
        }
    },
});

export default router;
